.gallery-main {
    background-color: #C2953A;
    width: 100%;
    height: auto;
}

.gallery-heading {
    margin-top: 2rem;
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    color: white;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
}

.gallery-title {
    font-size: 36px;
    line-height: 54px;
    font-weight: 600;
    color: white;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 3rem;
}

.development-process-1,
.development-process-2,
.development-process-3,
.development-process-4,
.development-process-5,
.development-process-6 {
    width: 100%;
    height: auto;
}

.development-process-1,
.development-process-2,
.development-process-5,
.development-process-6 {
    border-radius: 10%;
}

.development-process-3,
.development-process-4 {
    border-radius: 6%;
}

.rounded-button {
    margin: 5rem 0 5rem 0;
    padding: 15px 45px;
    font-size: 20px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    color: #ffffff;
    display: inline-block;
    text-transform: uppercase;
    background-color: #27213D;
    transition: background-color 0.3s ease;
}

.rounded-button:hover {
    background-color: #3e3363;
}

/* Media Queries */

/* M-Screens(max-width: 768px) */

@media (max-width: 768px) {

    .rounded-button {
        padding: 10px 30px;
        font-size: 16px;
    }

    .development-process-1,
    .development-process-2,
    .development-process-3,
    .development-process-4,
    .development-process-5,
    .development-process-6 {
        border-radius: 6%;
    }

    .development-process-1,
    .development-process-2,
    .development-process-5,
    .development-process-6 {
        border-radius: 6%;
    }
}

/* S-Screens(max-width: 480px) */

@media (max-width: 480px) {

    .rounded-button {
        padding: 10px 30px;
        font-size: 16px;
    }

    .development-process-1,
    .development-process-2,
    .development-process-3,
    .development-process-4,
    .development-process-5,
    .development-process-6 {
        border-radius: 6%;
    }

    .development-process-1,
    .development-process-2,
    .development-process-5,
    .development-process-6 {
        border-radius: 6%;
    }


    .gallery-heading {
        font-size: 14pxpx;
        line-height: 22px;
        font-weight: 400;
    }

    .gallery-title {
        font-size: 28px;
        line-height: 48px;
        font-weight: 600;
        margin-bottom: 2rem;
    }
}