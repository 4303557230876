.rawalpindi-main {
    width: 100%;
    height: auto;
}

.rawalpindi-1 {
    width: 100%;
    height: auto;
}

.rawalpindi-2 {
    display: grid;
    justify-content: center;
    width: 80%;
    height: auto;
}

.rawalpindi-heading {
    margin-top: 2rem;
    color: black;
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    text-align: center;
    font-family: 'Poppins', sans-serif;
}

.rawalpindi-title {
    color: black;
    font-size: 28px;
    line-height: 30px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 2rem;
    font-family: 'Poppins', sans-serif;
}

/* Media Queries */

/* L-Screens(max-width: 1024px) */
@media (max-width: 1024px) {


    .rawalpindi-heading {
        font-size: 30px;
        line-height: 38px;
        font-weight: 600;
    }

    .rawalpindi-title {
        font-size: 26px;
        line-height: 28px;
        font-weight: 400;
    }
}

/* M-Screens(max-width: 768px) */
@media (max-width: 768px) {


    .rawalpindi-heading {
        font-size: 28px;
        line-height: 36px;
        font-weight: 600;
    }

    .rawalpindi-title {
        font-size: 24px;
        line-height: 26px;
        font-weight: 400;
    }
}

/* S-Screens(max-width: 480px) */
@media (max-width: 480px) {


    .rawalpindi-heading {
        font-size: 22px;
        line-height: 32px;
        font-weight: 600;
    }

    .rawalpindi-title {
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
    }
}