.features-main {
    background-color: #27213D;
    width: 100%;
    height: auto;
    padding: 2rem;
}

.features-background {
    background-image: url('/public/assets/other-assets/footer-background.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
}

.features-heading {
    margin-top: 2rem;
    font-size: 22px;
    font-weight: 500;
    line-height: 28px;
    color: #C2953A;
    font-family: 'Poppins', sans-serif;
}

.features-title {
    margin-top: 1rem;
    font-size: 48px;
    font-weight: 600;
    line-height: 28px;
    font-family: 'Poppins', sans-serif;
    color: white;
}

.features-slider-main {
    margin-top: 2rem;
    width: 100%;
}

.features-slider {
    margin: 0 1rem;
    padding: 1rem 0;
}

.features-slider img {
    width: 100%;
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

/* Media Queries */

/* XL-Screens (max-width: 1440px) */
@media (max-width: 1440px) {
    .features-heading {
        font-size: 20px;
    }

    .features-title {
        font-size: 46px;
    }
}

/* L-Screens (max-width: 1024px) */
@media (max-width: 1024px) {
    .features-heading {
        font-size: 18px;
    }

    .features-title {
        font-size: 42px;
    }
}

/* M-Screens (max-width: 768px) */
@media (max-width: 768px) {
    .features-heading {
        font-size: 16px;
    }

    .features-title {
        font-size: 40px;
    }
}

/* S-Screens (max-width: 480px) */
@media (max-width: 480px) {
    .features-heading {
        font-size: 14px;
    }

    .features-title {
        font-size: 32px;
        line-height: 32px;
    }
}