.who-we-are-main {
    background-color: #c2953a3a;
    width: 100%;
    height: auto;
}

.who-we-are-1 {
    margin-top: 5rem;
    margin-bottom: 1rem;
}

.who-we-are-3 {
    margin-top: 1rem;
}

.who-we-are-1 img {
    border-radius: 10%;
}

.who-we-are-2 img {
    border-radius: 10%;
}

.group-6 img {
    border-radius: 10%;
}

.who-we-are-3 img {
    border-radius: 10%;
}

.who-we-are-head {
    margin-top: 10rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: black;
    text-transform: uppercase;
}

.who-we-are-title {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: black;
    font-weight: 700;
    margin-top: 1rem;
}

/* Media Queries */

/* L-Screens(1024px) */
@media (max-width: 1024px) {

    .who-we-are-head {
        margin-top: 5rem;
        font-size: 20px;
    }

    .who-we-are-title {
        font-size: 25px;
    }
}

/* MD-Screens(767px) */
@media (max-width: 767px) {

    .who-we-are-head {
        margin-top: 1rem;
        font-size: 16px;
    }

    .who-we-are-title {
        font-size: 18px;
    }

    .text-black {
        font-size: 14px;
    }

    .col-md-6 {
        flex: 0 0 auto !important;
        width: 50% !important;
    }
}

/* SM-Screens(480px) */
@media (max-width: 480px) {

    .who-we-are-head {
        margin-top: 1rem;
        font-size: 14px;
    }

    .who-we-are-title {
        font-size: 16px;
    }

    .text-black {
        font-size: 12px;
    }

    .col-md-6 {
        flex: 0 0 auto !important;
        width: 50% !important;
    }
}

/* X-SM-Screens(375px) */
@media (max-width: 375px) {

    .who-we-are-head {
        margin-top: 2rem;
        font-size: 12px;
    }

    .who-we-are-title {
        font-size: 14px;
    }

    .text-black {
        font-size: 10px;
    }

    .col-md-6 {
        flex: 0 0 auto !important;
        width: 50% !important;
    }
}