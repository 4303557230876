.main-banner-wrapper {
    height: 80vh;
    max-width: 100%;
    overflow: hidden;
}

.carousel {
    height: 100%;
}

.carousel-inner {
    height: 100%;
}

.carousel-item {
    height: 100%;
}

.first-slide {
    background-color: rgba(255, 255, 255, 0.756);
    width: 20%;
    color: black;
    padding: 2rem;
    text-align: center;
}

/* Media Queries */

/* XL-Screens(1440px) */

@media(max-width: 1440px) {

    .main-banner-wrapper {
        height: auto;
    }

    .third-slide h1 {
        font-size: 42px !important;
    }
}

/* L-Screens(1024px) */

@media(max-width: 1024px) {

    .main-banner-wrapper {
        height: auto;
    }

    .first-slide h3 {
        font-size: 25px !important;
    }

    .third-slide h1 {
        font-size: 32px !important;
    }
}

/* MD-Screens(900px) */

@media(max-width: 900px) {

    .main-banner-wrapper {
        height: auto;
    }

    .first-slide {
        display: none;
    }

    .third-slide {
        display: none;
    }
}