.contact-main {
    width: 100%;
    height: auto;
    padding: 2rem;
    background-color: rgb(240, 240, 240);
}

.contact-heading {
    font-size: 1.5rem;
    line-height: 2rem;
}

.contact-title {
    font-size: 2.5rem;
    line-height: 3rem;
}

.contact-desc {
    font-size: 1rem;
    line-height: 1.5rem;
}

.form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 450px;
    gap: 1rem;
}

input,
textarea {
    width: 100%;
    font-size: 1rem;
    border: 1px solid #ccc;
}

input {
    padding: 1rem;
    border-radius: 50px;
}

textarea {
    padding: 2rem;
    border-radius: 30px;
}

.submit-button {
    background-color: #27213D;
    color: white;
    width: 40%;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    padding: 15px 15px;
    border: none;
    border-radius: 40px;
    cursor: pointer;
    width: 40%;
}

.contact-img img {
    width: 100%;
    margin: 0 auto;
}

@media screen and (max-width: 768px) {
    .contact-title {
        font-size: 2rem;
        line-height: 2.5rem;
    }

    .contact-desc {
        font-size: 0.9rem;
        line-height: 1.3rem;
    }
}

@media screen and (max-width: 480px) {
    .contact-heading {
        font-size: 1.2rem;
        line-height: 1.8rem;
    }

    .contact-title {
        font-size: 1.8rem;
        line-height: 2.3rem;
    }

    .contact-desc {
        font-size: 0.8rem;
        line-height: 1.2rem;
    }

    .form-container {
        max-width: none;
    }

    .contact-img img {
        margin-top: 2rem;
    }
}