* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    background: #000;
}

.projects-main {
    width: 100%;
    height: auto;
}

.master-city-projects-head {
    margin-top: 1rem;
    font-weight: 700;
}

.parent {
    margin-top: 2rem;
    left: 0;
    width: 100%;
    height: auto;
}

.slider {
    margin: 0 20px;
    overflow: "hidden";
    padding: 2rem 0;
}

.slider img {
    width: 100%;
    filter: opacity(0.8) drop-shadow(0 0 #c2953a);
}

.react-multi-carousel-list {
    padding: 0rem 0 2rem 0;
}

.custom-dot-list-style button {
    border: none;
    background: rgba(0, 0, 0, 0.304);
}

.react-multi-carousel-dot.react-multi-carousel-dot--active button {
    background: #C2953A;
}

.carousel-text {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-family: 'Poppins', sans-serif;
}

.carousel-text h4 {
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
}

.carousel-text h2 {
    font-size: 48px;
    font-weight: 700;
    line-height: 38px;
}

/* Media Queries */

/* XL-Screens(max-width:1440px) */

@media (max-width: 1440px) {

    .carousel-text h4 {
        font-size: 28px;
        font-weight: 600;
        line-height: 32px;
    }

    .carousel-text h2 {
        font-size: 42px;
        font-weight: 700;
        line-height: 32px;
    }
}

/* L-Screens(max-width:1024px) */

@media (max-width: 1024px) {

    .carousel-text h4 {
        font-size: 24px;
        font-weight: 600;
        line-height: 28px;
    }

    .carousel-text h2 {
        font-size: 28px;
        font-weight: 700;
        line-height: 28px;
    }
}

/* M-Screens(max-width:768px) */

@media (max-width: 768px) {

    .carousel-text h4 {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
    }

    .carousel-text h2 {
        font-size: 32px;
        font-weight: 600;
        line-height: 24px;
    }
}

/* S-Screens(max-width:480px) */

@media (max-width: 480px) {

    .carousel-text h4 {
        font-size: 30px;
        font-weight: 600;
        line-height: 34px;
    }

    .carousel-text h2 {
        font-size: 42px;
        font-weight: 700;
        line-height: 34px;
    }

    .slider img {
        width: 80%;
    }

    .carousel-text {
        top: 40%;
        left: 40%;
    }
}