.footer {
    background-color: #27213D;
    color: #fff;
    padding: 5rem 0;
}

.footer-background {
    background-image: url('/public/assets/other-assets/footer-background.png');
    background-repeat: no-repeat;
    background-position: left center;
    width: 100%;
}

.footer h2 {
    color: #fff;
    font-size: 32px;
    line-height: 40px;
}

.footer p {
    margin-bottom: 20px;
}

.social-icons i {
    display: inline-block;
    margin-right: 10px;
    color: #fff;
    font-size: 20px;
}

.social-icons i:hover {
    color: #ccc;
}

.footer ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer ul li {
    margin-bottom: 10px;
}

.footer ul li a {
    color: #fff;
    text-decoration: none;
}

.footer ul li a:hover {
    color: #ccc;
}

.address,
.phone,
.email {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.btn-primary {
    background-color: #C2953A !important;
    border-color: #C2953A !important;
}

/* Media Queries */

/* L-Screens(max-width: 991px) */
@media (max-width: 991px) {

    .col-lg-3 {
        text-align: center;
    }
}

/* M-Screens(max-width: 768px) */
@media (max-width: 768px) {

    .address,
    .phone,
    .email {
        flex-direction: column;
    }

    .col-lg-3 {
        text-align: center;
    }
}

/* S-Screens(max-width: 575px) */
@media (max-width: 575px) {

    .footer ul li a {
        font-size: 15px;
    }

    .col-lg-3 {
        text-align: center;
    }
}