.about-main {
    width: 100%;
    height: auto;
}

.about-heading {
    margin-top: 2rem;
    text-align: center;
    font-size: 38px;
    line-height: 32px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    color: black;
}

@media (max-width: 576px) {
    .about-1 {
        width: 50%;
    }
}