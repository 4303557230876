.logo {
    margin: 4rem 0 0 4rem;
}

.logo img {
    width: 100%;
    height: auto;
}

.navbar-with-background {
    background-image: url('/public/assets/images/development-process/header-2.jpg');
    background-position: right center;
    background-repeat: no-repeat;
    background-size: contain;
}

.navbar {
    margin: 4rem 0 0 2rem;
    height: 45%;
}

.nav-link {
    color: white !important;
    font-size: 21px !important;
    font-weight: 600 !important;
    line-height: 21px !important;
}

.offcanvas {
    background-color: black !important;
}

/* Media Queries */

/* XL-Large Screens(1440px) */

@media (max-width: 1440px) {

    .logo {
        margin: 4rem 2rem 0 1rem;
    }

    .navbar {
        margin: 4rem 0 0 1rem;
    }

    .nav-link {
        font-size: 18px !important;
    }
}

/* Large Screens(1024px) */

@media (max-width: 1024px) {

    .logo {
        margin: 4rem 1rem 0 1rem;
    }

    .navbar {
        margin: 4rem 0 0 0;
    }

    .nav-link {
        font-size: 14px !important;
    }
}

/* Medium Screens(768px) */

@media (max-width: 768px) {

    .logo {
        margin: 4rem 1rem 0 0;
    }

    .navbar {
        margin: 4rem 0 0 0;
        height: 40%;
    }

    .navbar-toggler-icon {
        font-size: 15px;
        border: none !important;
    }
}

/* Small Screens(480px) */

@media (max-width: 480px) {

    .logo {
        margin: 4rem 1.5rem 0 0;
    }

    .logo img {
        width: 140%;
        height: auto;
    }

    .navbar {
        margin: 4rem 0 0 0;
    }

    .background-img {
        width: 100%;
        height: 30%;
    }

    .navbar-toggler-icon {
        font-size: 12px;
        border: none !important;
    }
}