.advantages-heading {
    margin-top: 4rem;
    font-size: 38px;
    line-height: 30px;
    color: black;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 3rem;
}

.advantages-icons {
    font-size: 25px;
    margin-right: 1rem;
    color: #C2953A;
}

.advantages-icons-arrow {
    font-size: 30px;
    margin-right: 1rem;
}

.advantages-col {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
    margin-bottom: 4rem;
}

/* Media Queries */

/* XL-Screens(max-width: 1440px) */

@media (max-width: 1440px) {
    .advantages-heading {
        font-size: 36px;
        line-height: 30px;
    }

    .card-body h5 {
        font-size: 18px;
        line-height: 32px;
    }

    .card-body h3 {
        font-size: 26px;
        line-height: 32px;
    }
}

/* L-Screens(max-width: 1024px) */

@media (max-width: 1024px) {
    .advantages-heading {
        font-size: 34px;
        line-height: 30px;
    }

    .card-body h5 {
        font-size: 16px;
        line-height: 30px;
    }

    .card-body h3 {
        font-size: 22px;
        line-height: 30px;
    }
}

/* L-Screens(max-width: 991px) */

@media (max-width: 991px) {
    .advantages-col {
        grid-template-columns: repeat(2, 1fr);
    }

    .advantages-heading {
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 3rem;
    }

    .card-body h5 {
        font-size: 16px;
        line-height: 28px;
    }

    .card-body h3 {
        font-size: 20px;
        line-height: 28px;
    }
}

/* M-Screens(max-width: 767px) */

@media (max-width: 767px) {
    .advantages-col {
        grid-template-columns: repeat(2, 1fr);
    }

    .advantages-heading {
        font-size: 28px;
        line-height: 28px;
        margin-bottom: 2rem;
    }

    .card-body h5 {
        font-size: 14px;
        line-height: 26px;
    }

    .card-body h3 {
        font-size: 18px;
        line-height: 26px;
    }
}

/* S-Screens(max-width: 576px) */

@media (max-width: 576px) {
    .advantages-col {
        grid-template-columns: repeat(1, 1fr);
        justify-content: center;
    }

    .advantages-heading {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 2rem;
        text-align: center;
    }

    .card {
        width: 18rem;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }

    .card-body h5 {
        font-size: 12px;
        line-height: 24px;
    }

    .card-body h3 {
        font-size: 16px;
        line-height: 24px;
    }
}

/* XS-Screens(max-width: 375px) */

@media (max-width: 375px) {
    .advantages-col {
        grid-template-columns: repeat(1, 1fr);
        justify-content: center;
    }

    .advantages-heading {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 2rem;
        text-align: center;
    }

    .card {
        width: 18rem;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }

    .card-body h5 {
        font-size: 12px;
        line-height: 24px;
    }

    .card-body h3 {
        font-size: 16px;
        line-height: 24px;
    }
}