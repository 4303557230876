.small-nav {
    left: 0;
    top: 0;
    height: auto;
    width: 100%;
    position: absolute;
    background-color: var(--color-darkgoldenrod-100);
}

.container {
    margin: 0;
}

.navbar-icon-email {
    margin-right: 7px;
}

.navbar-icon-phone {
    margin-right: 5px;
}

.navbar-text-email {
    margin-right: 30px;
}

@media (max-width: 768px) {

    .navbar-text {
        font-size: 12px;
    }

    .navbar-icon {
        font-size: 12px;
    }
}

@media (max-width: 480px) {

    .navbar-text {
        display: none;
    }

    .navbar-icon-email {
        margin-right: 27px;
    }

    .navbar-icon {
        display: inline-block;
        font-size: 15px;
    }
}