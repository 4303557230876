body {
  margin: 0;
  line-height: normal;
}

:root {
  /* fonts */
  --headings-regular-16: Raleway;
  --font-roboto: Roboto;
  --font-poppins: Poppins;
  --font-font-awesome-5-free: "Font Awesome 5 Free";
  --font-font-awesome-6-brands: "Font Awesome 6 Brands";

  /* font sizes */
  --headings-regular-16-size: 16px;
  --font-size-3xl: 22px;
  --font-size-lg: 18px;
  --font-size-17xl: 36px;
  --font-size-13xl: 32px;
  --font-size-xl: 20px;
  --font-size-sm: 14px;
  --font-size-5xl: 24px;
  --font-size-29xl: 48px;

  /* Colors */
  --color-white: #fff;
  --black-black-30: #77808b;
  --black-black-70: #283646;
  --color-darkslategray-100: #333;
  --white-white-30: #f2f4f5;
  --color-darkgray-100: #a6a6a6;
  --color-darkgray-200: #999;
  --color-gray-100: #fefeff;
  --color-gray-200: #27213d;
  --color-black: #000;
  --color-darkgoldenrod-100: #c2953a;
  --color-gainsboro-100: #ddd;

  /* Gaps */
  --gap-11xs: 2px;

  /* Paddings */
  --padding-xs: 12px;
  --padding-13xl: 32px;

  /* border radiuses */
  --br-5xs: 8px;
  --br-6xl: 25px;
  --br-81xl: 100px;

  /* Effects */
  --shadow: 35px 35px 65px rgba(146, 106, 120, 0.16);
}